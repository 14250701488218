import React, { useEffect} from "react";
import * as s from "./styles/globalStyles";
import {ResponsiveWrapper , StyledImg} from "./components";
import {Helmet} from "react-helmet";
import WebFont from 'webfontloader';
import { NavBar } from "./navbar";

function App() {  
  let date = new Date();
  let year = date.getFullYear();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  WebFont.load({
    custom: {
      families: ['font'],
    },
  });
return (
<s.Screen>
 <s.ScrollBar>
      <s.Container
        flex={1}
        ai={"relative"}
        style={{
          padding: 0,
          width:"100%",
          height:"100%",
          margin: 0,
          overflowX: "hidden",
          overflowY: "scroll",
          backgroundColor: "rgba(0, 0, 0, 1 )"}}
        >
<NavBar/>
        <ResponsiveWrapper className="img-gallery" style={{marginTop: "10px" ,padding: 0, backgroundColor: "rgba(0, 0, 0, 0.96)"}} >
          <s.Container flex={2} jc={"justify"} ai={"center"} style={{ padding:2}}>
          <div className="img-container">
            <StyledImg className="image" alt="aglaonema (still life)"
              src={"./config/images/stilllife_aglaonema.webp"}
              data-original="stilllife_aglaonema.webp"
            /> 
            </div> 
            <div className="img-container">
            <StyledImg className="image" alt="abandoned noo-noo" 
              src={"./config/images/abandoned_noo_noo.webp"}
              data-original="abandoned_noo_noo.webp"
            /> 
            </div> 
            <div className="img-container">
            <StyledImg className="image" alt="ghosts of puumala" 
              src={"./config/images/ghosts_of_puumala.webp"}
              data-original="ghosts_of_puumala.webp"
            /> 
            </div> 
            <div className="img-container">
            <StyledImg className="image" alt="valerian dreams" loading="lazy" 
              src={"./config/images/valerian_dreams.webp"}
              data-original="valerian_dreams.webp"
            />
        </div>
          <div className="img-container">
            <StyledImg className="image"  alt="Anon/You'll never know" loading="lazy" 
              src={"./config/images/anon.webp"}
              data-original="anon.webp" />
             </div>
             <div className="img-container">
             <StyledImg className="image" loading="lazy" alt="this is not a game"
              src={"./config/images/we_re_not_playin.webp"}
              data-original="we_re_not_playin.webp"
            /> 
            </div> 
            <div className="img-container">
            <StyledImg className="image" loading="lazy" alt="symbiosis I"
              src={"./config/images/symbiosisI.webp"}
              data-original="symbiosisI.webp"
            /> 
            </div>    
            </s.Container>
            <s.Container flex={2} jc={"justify"} ai={"center"} style={{ padding:2}}>
            <div className="img-container">
            <StyledImg className="image" alt="Pink Bite"
              src={"./config/images/pink_bite.webp"}
              data-original="pink_bite.webp"
            />   
            </div>   
            <div className="img-container">
            <StyledImg className="image" alt="3D chess board"
              src={"./config/images/3D_chess_board_topview.webp"}
              data-original="3D_chess_board_topview.webp"
            />  
            </div> 
        <div className="img-container">
            <StyledImg className="image" loading="lazy" alt="cigarra gran passo"
              src={"./config/images/flowers&wine.webp"}
              data-original="flowers&wine.webp"
            /> 
            </div>
            <div className="img-container">
            <StyledImg className="image" alt="I was not here" loading="lazy" 
              src={"./config/images/i_was_not_here2.webp"}
              data-original="i_was_not_here2.webp"
            /> 
            </div>   
            <div className="img-container">
            <StyledImg className="image" alt="everything sucks" loading="lazy" 
              src={"./config/images/everything_sucks.webp"}
              data-original="everything_sucks.webp"
            /> 
            </div>  
            <div className="img-container"> 
            <StyledImg className="image" alt="projection" loading="lazy"
              src={"./config/images/hood.webp"}
              data-original="hood.webp"
            /></div>      
           </s.Container>
          <s.Container flex={2} jc={"justify"} ai={"center"} style={{ padding:2}}>
          <div className="img-container">
            <StyledImg className="image" alt="the only thing she loved"
              src={"./config/images/roses_were_the_only_thing.webp"}
              data-original="roses_were_the_only_thing.webp"
            /> 
            </div>  
            <div className="img-container">
            <StyledImg className="image" alt="Threat Kreator"
              src={"./config/images/TK.webp"}
              data-original="TK.webp"
            />  
            </div>   
            <div className="img-container">
            <StyledImg className="image" loading="lazy" alt="flowers suck"
              src={"./config/images/flowers_suck.webp"}
              data-original="flowers_suck.webp"
            />  
            </div>   
            <div className="img-container">
            <StyledImg  flex={2} className="image" loading="lazy" alt="B1tch"
              src={"./config/images/b1tch.webp"}
              data-original="b1tch.webp"
            /> 
            </div> 
            <div className="img-container">
            <StyledImg className="image"alt="home (Oulucore)" loading="lazy"
              src={"./config/images/landscape_oulucore_small.webp"}
              data-original="landscape_oulucore_small.webp"
            /> 
            </div>
            <div className="img-container">
            <StyledImg className="image" loading="lazy" alt="serpent"
              src={"./config/images/serpent.webp"}
              data-original="serpent.webp"
            />  
            </div> 
</s.Container>
<div flex={2} className="modal">
      <img src={".config/images/b1tch.webp"} flex={2} alt="" className="big-image"/>
      <p  className="caption"></p>
      <span  className="close-btn">&times;</span>
    </div>
    <Helmet async >
    <script async src="./script.js"></script>
    </Helmet>
          </ResponsiveWrapper>
          <s.TextDescription
                  style={{marginTop:"50px",textAlign:"center", fontSize:13,color:"white", marginBottom:10, width:"100%"}}>
                  © {year}  Threat Kreator All rights reserved
                  </s.TextDescription>
            </s.Container>
              </s.ScrollBar>
            </s.Screen>
  );
}
export default App
;