import React, { useEffect} from "react";
import {Link} from 'react-router-dom';
import * as s from "./styles/globalStyles";
import {ResponsiveWrapperBio} from "./components";
import WebFont from 'webfontloader';

function Bio() {  
  let date = new Date();
  let year = date.getFullYear();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  WebFont.load({
    custom: {
      families: ['font'],
    },
  });
return (
<s.Screen>
  <s.ScrollBar>
  <s.Container
        flex={1}
        ai={"relative"}
        style={{
        padding: 0,
        width: '100vw',
        height:"100%",
        margin: 0,
        overflowX: "hidden", 
        overflowY: "scroll",
        backgroundColor: "rgba(0, 0, 0, 0)"
        }}
        >

 <s.Container style={{
    width: "100%",
    margin: 0,
    padding: 0,
    alignItems: "center",
    boxShadow: "0 0 0 6px #000000, 0 0 0 5px #000000, 0 0 0 6px #000000, 3px 4px 10px 3px rgb(9, 255, 231)",
    position: "relative",
    border: "1px rgb(9, 255, 231)",
    borderStyle:"none none solid",
    backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
      <Link to="/" style={{ textDecoration:"none"}}>
      <s.TextTitle
      style={{
        padding: 0,
        textAlign:"center",
        marginTop:"15px",
        marginBottom:"4px",
        animation: "flicker 4s infinite",
        color: "#e6ffff",
        textShadow:
        "0 0 7px #66ffff, 0 0 10px #66ffff, 0 0 10px  #66ffff,  0 0 21px  #66ffff, 0 0 42px #00ffff, 0 0 82px #00ffff, 0 0 20px #00ffff, 0 0 80px #00ffff, 0 0 60px #00ffff"
      }} 
      > THREAT KREATOR'S ART CAVE 
      </s.TextTitle>
      </Link> 
      <div className="dropdown">
     <button style={{ backgroundImage: "linear-gradient(#66ffff, #66ffcc, white)"}}  className="button"> main menu </button>
     <div className="content">
      <a style={{ backgroundImage: "linear-gradient(#66ffff, #66ffcc, white)"}}  href="/">Gallery</a>
      <a style={{ backgroundImage: "linear-gradient(#66ffff, #66ffcc, white)"}} href="/about">Story</a>
      <a style={{ backgroundImage: "linear-gradient(#66ffff, #66ffcc, white)"}} href="/artcave">Art Cave</a>
      <a style={{ backgroundImage: "linear-gradient(#66ffff, #66ffcc, white)"}} href="/animations">Animations</a>
      <a style={{ backgroundImage: "linear-gradient(#66ffff, #66ffcc, white)"}} href="https://www.instagram.com/threat_kreator/" aria-label="Instagram">Contact</a>
     </div>
     </div>   
  </s.Container>
  <video id="background-video" autoPlay loop muted poster="config/images/forest.webp">
<source src={require('./videos/forest.webm')} type="video/webm"/>
</video>
          <ResponsiveWrapperBio
                  style={{     
                    alignSelf:"center",
                    alignItems:"center",
                    boxShadow: "0 0 0 1px rgb(9, 255, 231), 0 0 0 1px rgb(9, 255, 231), 0 0 0 1px rgb(9, 255, 231), 0px 0px 3px 4px rgb(9, 255, 190)",
                    borderStyle:"solid",
                    backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
          <s.Container
        flex={1}
        style={{
          width: "100%",
          padding: 0,
          alignItems: "center" }}>
          <s.TextDescription
              style={{
                width: "80%",
                fontWeight: "bold",
                marginBottom: 0,
                marginTop: "10px",
                color: "white",
              }}
            >
              Threat Kreator
            </s.TextDescription>
            <s.TextDescription
              style={{
                width: "80%",
                color:"rgba(251, 234, 243, 0.8)"
              }}
            >
            is a disturbed game artist from Finland suffering in the horrors of the corporate world.
              Beautiful, weird and terrifying.
              </s.TextDescription> 
              <s.TextDescription
              style={{
                width: "80%",
                color:"rgba(251, 234, 243, 0.8)"
              }}
            >
              Occasionally she gets locked up in the art cave to release emotional burden and frustration caused by society and social norms.
              </s.TextDescription> 
              <s.TextDescription
              style={{
                width: "80%",
                color:"rgba(251, 234, 243, 0.8)"
              }}
            >
              Threat Kreator's digital sphere consist of story-driven digital paintings, creepy videos and legendary comic art.
              </s.TextDescription> 
              <s.TextDescription
              style={{
                marginBottom: 10,
                width: "80%",
                color:"rgba(251, 234, 243, 0.8)",
              }}
            >
              "The cooolest digital art with software as the brush and pixels as the paint." -  Based art critic
              </s.TextDescription> 

              </s.Container>
            </ResponsiveWrapperBio>
            <s.TextDescription
                  style={{marginTop:"50px",textAlign:"center", fontSize:13,color:"white", marginBottom:10, width:"100%"}}>
                  © {year} Threat Kreator All rights reserved
                  </s.TextDescription>
            </s.Container>
            </s.ScrollBar>
            </s.Screen>   
  );
}
export default Bio;