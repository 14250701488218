import React, { useEffect} from "react";
import * as s from "./styles/globalStyles"; 
import WebFont from 'webfontloader';
import { NavBar } from "./navbar";

function Bore() {  
  let date = new Date();
  let year = date.getFullYear();
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  WebFont.load({
    custom: {
      families: ['font'],
    },
  });
return (
<s.Screen>
 <s.ScrollBar>
      <s.Container
        flex={1}
        ai={"relative"}
        style={{
          padding: 0,
          width:"100vw",
          height:"100%",
          margin: 0,
          overflowX: "hidden",
          overflowY: "scroll",
          backgroundColor: "rgba(0, 0, 0, 0.96)"}}
        >
        <NavBar/>
        <s.Container style={{maxWidth:"100%", alignContent:"center", alignItems:"center", margin:15}}>
          <s.TextSubTitle
        style={{
          textAlign:"center",
          marginTop: 50,
          width:"100%",
          position:"center",
          }}>
            unreleased art cave tapes
          </s.TextSubTitle>
          <iframe  width="90%" height="400" src="https://www.youtube.com/embed/Y2SDMB3WleE?si=8IanHr2kyzkg6OdZ"
          title="YouTube video player" frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen>            
          </iframe>
          <s.TextDescription
          style={{
                width: "100%",
                fontWeight: "bold",
                marginBottom: 0,
                marginTop: "10px",
                color: "white",
                textAlign:"center"
              }}>
            art cave tape 1
          </s.TextDescription>
          <s.TextDescription
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    marginBottom: 0,
                    marginTop: "10px",
                    color: "white",
                    textAlign:"center"
                  }}>
            date: 14.3
          </s.TextDescription>
          <s.TextDescription
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    marginBottom: 0,
                    marginTop: "10px",
                    color: "white",
                    textAlign:"center"
                  }}>
            location: under the bridge
          </s.TextDescription>

            <s.TextDescription
                  style={{marginTop:"50px",textAlign:"center", fontSize:13,color:"white", marginBottom:10, width:"100%"}}>
                  © {year}  Threat Kreator All rights reserved
                  </s.TextDescription>
          </s.Container>
            </s.Container>
              </s.ScrollBar>
            </s.Screen>
  );
}
export default Bore
;